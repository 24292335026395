/* Main container styling */
.success_card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  /* Card box styling */
  .success_card-box {
    background: white;
    border: 1px solid #4caf50;
    border-radius: 20px;
    text-align: center;
    padding: 30px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  /* Title styling */
  .success_card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Message styling */
  .success_card-message {
    font-size: 1rem;
    color: #555;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  /* Highlighted text */
  .success_card-count {
    font-weight: bold;
    color: #4caf50;
  }
  
  /* Button styling */
  .success_card-button {
    background-color: #000;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .success_card-button:hover {
    background-color: #4caf50;
    transform: scale(1.05);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .success_card-box {
      padding: 20px 15px;
    }
  
    .success_card-title {
      font-size: 1.2rem;
    }
  
    .success_card-message {
      font-size: 0.9rem;
    }
  
    .success_card-button {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  }
  
  @media (max-width: 480px) {
    .success_card-box {
      border-radius: 15px;
    }
  
    .success_card-title {
      font-size: 1rem;
    }
  
    .success_card-message {
      font-size: 0.8rem;
    }
  
    .success_card-button {
      font-size: 0.8rem;
      padding: 7px 12px;
    }
  }
  