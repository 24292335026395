.details-container {
    max-width: 1200px;
    margin: 5% auto;
    padding: 20px;
}

.details-header {
    text-align: center;
    padding: 20px;
    background-color: #3498db;
    color: white;
}

.details-title {
    margin: 0;
    font-size: 32px;
}

.details-location {
    font-size: 18px;
}

/* .details-image {
    width: 95%;
    height: 400px;
    object-fit: contain;
    margin-top: 20px;
    box-sizing: border-box;
} */

.details-info {
    display: flex;
    margin-top: 20px;
}

.details-section, .details-stats {
    width: 100%;
    height: fit-content;
    gap:20px;
    flex: 1;
    margin-right: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.details-section-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.details-description, .details-features {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}
.reward-auth-deatail-share {
    position: fixed;
    top: 0;
    left: 0;
    
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it appears above all content */
  }
  .detail-share-modal-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color:white; /* White background */
     /* Space inside the modal */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    width: 90%; /* Full width on smaller screens */
    max-width: 500px; /* Limit the maximum width */
    height: 600px;
    overflow: hidden; 
  }
  

.details-invest-btn {
    display: inline-flex;
    align-items: center;
    padding: 15px 25px;
    background-color: #2b8a3e;
    color: white;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    cursor: pointer;
}

.details-invest-btn:hover {
    background-color: #1f6630;
}

.details-stats-list {
    list-style-type: none;
    padding: 0;
}

.details-stats-item {
    font-size: 18px;
    margin: 10px 0;
}
.photo-map{
    gap:20px;
    margin-top:10px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

/* .photo{
    background-color: white;
    width: 100%;
    height:auto;
    display: flex;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
} */

.map{
    background-color: white;
    width: 100%;
    height:auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.layout{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width:770px){

    .photo-map , .details-info{
        flex-direction: column;
        justify-content: space-between;
        gap:10px;
    }
    

    .details-section,.details-stats{
        width:100%
    }
}

.photo {
    background-color: white;
    position: relative;
    width: 100%;      /* Adjust as per your layout (you can use fixed width like 600px if needed) */
    /* max-width: 600px; Set a maximum width for the container */
    height: 400px;    /* Set a fixed height for the container */
    overflow: hidden; /* Hide any overflowing parts of the image */
   
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;   /* Center the photo container */
  }
  
  .details-image {
    width: 90%;      /* Make sure the image takes the full width of the container */
    height: 100%;     /* Ensure the image fills the container height */
   margin: auto;
    /* margin-top: 20px; */
     object-fit: contain; /* Crop the image to cover the container without distortion */
  }

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(203 203 203 / 65%);
    color: rgb(0, 0, 0);
    border: none;
    padding: 2px;
    cursor: pointer;
    z-index: 1;
  }
  .arrow:hover{
    background-color: #3498db;

  }
  .left-arrow {
    left: 5px;
  }
  
  .right-arrow {
    right: 5px;
  }