/* General Styles */
.monitorland-container {
    font-family: "Arial, sans-serif";
    color: #333;
    background: transparent;
    padding: 20px;
    /* border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    animation: fadeIn 1s ease-in-out;
    max-width: 900px;
    margin: 5% auto;
    line-height: 1.6;
  }
  
  .monitorland-header h1 {
    font-size: 2rem;
    text-align: center;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .monitorland-header .highlight {
    color: #2980b9;
    font-weight: bold;
  }
  
  .monitorland-header p {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .monitorland-why, .monitorland-how {
    margin-top: 20px;
  }
  
  .monitorland-card {
    background: #e8f5ff;
    margin-bottom: 15px;
    padding: 15px;
    border-left: 5px solid #2980b9;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  .monitorland-card:hover {
    transform: scale(1.02);
  }
  
  .monitorland-card h3 {
    color: #2c3e50;
  }
  
  .monitorland-card ul {
    list-style: none;
    padding: 0;
  }
  
  .monitorland-card li {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 0.95rem;
  }
  
  .monitorland-card li strong {
    margin-left: 5px;
  }
  
  .monitorland-steps p {
    background: #fff;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .monitorland-steps strong {
    margin-left: 5px;
  }
  
  .monitorland-cta {
    text-align: center;
    margin-top: 30px;
  }
  
  .monitorland-cta p {
    margin-bottom: 20px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #2980b9;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .cta-button:hover {
    background-color: #1c669e;
    transform: scale(1.05);
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .monitorland-container {
      padding: 15px;
      margin: 15% auto;
    }
  
    .monitorland-header h1 {
      font-size: 1.6rem;
    }
  
    .monitorland-card {
      font-size: 0.9rem;
      padding: 10px;
    }
  
    .monitorland-cta p {
      font-size: 0.9rem;
    }
  
    .cta-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  