/* General Styles */
.freepromo-container {
    font-family: 'Arial, sans-serif';
    color: #333;
    line-height: 1.6;
   
    max-width: 800px;
    /* margin: auto; */
    margin: 5% auto;
    padding: 20px;
    background:transparent;
   /*  border-radius: 10px; */
   /*  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    animation: fadeIn 1s ease-in-out;
  }
  
  .freepromo-header h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .freepromo-header .highlight {
    color: #2980b9;
    font-weight: bold;
  }
  
  .freepromo-why, .freepromo-steps, .freepromo-included {
    margin-top: 20px;
  }
  
  .freepromo-why ul, .freepromo-included ul {
    list-style: none;
    padding: 0;
  }
  
  .freepromo-why li, .freepromo-included li {
    background: #e8f5ff;
    padding: 10px;
    margin-bottom: 10px;
    border-left: 5px solid #2980b9;
    transition: transform 0.3s;
  }
  
  .freepromo-why li:hover, .freepromo-included li:hover {
    transform: scale(1.02);
  }
  
  .freepromo-steps ol {
    padding-left: 20px;
  }
  
  .freepromo-cta {
    text-align: center;
    margin-top: 30px;
  }
  
  .freepromo-cta p {
    margin-bottom: 20px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #2980b9;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out, transform 0.2s;
  }
  
  .cta-button:hover {
    background-color: #1c669e;
    transform: scale(1.05);
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .freepromo-container {
      padding: 15px;
      margin: 15% auto;
    }
  
    .freepromo-header h1 {
      font-size: 1.5rem;
    }
  
    .freepromo-why li, .freepromo-included li {
      font-size: 0.9rem;
    }
  
    .freepromo-steps ol {
      padding-left: 10px;
    }
  
    .cta-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  