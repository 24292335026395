body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 5% auto;
    padding: 20px;
    margin-bottom: 0;

}

.property-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
}

.property-tile {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.property-tile:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.property-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.property-info {
    padding: 15px;
}

.property-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px;
}

.property-location {
    color: #666;
    font-size: 14px;
    margin-bottom: 10px;
}

.property-price {
    color: #2b8a3e;
    font-size: 16px;
    margin-bottom: 10px;
}

.property-status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #3498db;
    color: white;
    font-size: 16px;
    
    margin-right: 10px;
}

.property-status.sold {
    background-color: #e74c3c;
}

.view-details-btn {
    display: inline-block;
    
    padding: 10px 15px;
    background-color: #3498db;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.view-details-btn:hover {
    background-color: #2980b9;
}


.container-tab{
    margin-top: 20px;
    width: 100%;
    display: flex;
   flex-direction: row;
   justify-content: space-around;
    border-radius: 4px;
    background-color: white ;
   /*  border: 1px solid #ddd; */
    color: black;
    border-radius: 1px solid  #2980b9 ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

/* .container-tab:hover{
    background-color:  #2980b9  ;
} */

.ulclass{
    list-style: inside;
    font-weight: bold;
    font-size: 18px;
}

.ulclass:hover{
    border-radius: 4px;
    /* background-color:  #2980b9  ; */
    color:blue;
}
.liclass{
    font-weight: 100;
    font-size: 14px;
    color: #666;
}
.liclass:hover{
 color: #2980b9;
}

.anounce{
    width: 18px;
    height: 18px;
}




/* Responsive Layout */
@media (max-width: 1024px) {
    .property-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .container{
        margin-top:10%;
    }
    .property-grid {
        grid-template-columns: 1fr;
    }

    .property-image {
        height: 180px;
    }
    .container-tab{
        display: flex;
        flex-direction: column;
    }
}

@media screen and  (max-width: 480px) {
    .container{
        margin-top:10%;
    }
    .property-info {
        padding: 10px;
    }

    .property-title {
        font-size: 16px;
    }

    .property-price {
        font-size: 14px;
    }

    .view-details-btn {
        padding: 8px 10px;
    }
    .container-tab{
        display: flex;
        flex-direction: column;
    }
}