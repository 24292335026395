/* General Styles */
.earnland-container {
    font-family: "Arial, sans-serif";
    color: #333;
    background: transparent;
    padding: 20px;
    /* border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    animation: fadeIn 1s ease-in-out;
    max-width: 900px;
    margin: 5% auto;
    line-height: 1.6;
  }
  
  .earnland-header h1 {
    font-size: 2rem;
    text-align: center;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .earnland-header .highlight {
    color: #2980b9;
    font-weight: bold;
  }
  
  .earnland-section, .earnland-how, .earnland-why, .earnland-cta {
    margin-top: 20px;
  }
  
  .earnland-how h2, .earnland-why h2, .earnland-cta h2 {
    color: #2980b9;
    font-size: 1.5rem;
  }
  
  .earnland-card {
    background: #e8f5ff;
    margin-bottom: 15px;
    padding: 15px;
    border-left: 5px solid #2980b9;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  .earnland-card:hover {
    transform: scale(1.02);
  }
  
  .earnland-card h3 {
    margin-bottom: 10px;
  }
  
  .earnland-card .tip {
    display: block;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #555;
  }
  
  .earnland-why ul {
    list-style: none;
    padding: 0;
  }
  
  .earnland-why li {
    background: #fff;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    
  }
  
  .earnland-why li strong {
    margin-left: 5px;
  }
  
  .earnland-cta {
    text-align: center;
    margin-top: 30px;
  }
  
  .earnland-cta p {
    margin-bottom: 20px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #2980b9;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #1c669e;
    transform: scale(1.05);
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .earnland-container {
      padding: 15px;
    
      margin: 15% auto;
    }
  
    .earnland-header h1 {
      font-size: 1.6rem;
    }
  
    .earnland-card {
      font-size: 0.9rem;
      padding: 10px;
    }
  
    .earnland-cta p {
      font-size: 0.9rem;
    }
  
    .cta-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  