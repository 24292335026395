/* Container */
.redeem_container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    /* border: 2px solid #a8d1a0; */
    border-radius: 15px;
    text-align: center;
    font-family: "Arial", sans-serif;
    /* background-color: #f9f9f9; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Title and subtitle */
  .redeem_title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .redeem_subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  /* Points Section */
  .redeem_points {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .redeem_points-label {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .redeem_points-value {
    font-size: 20px;
    font-weight: bold;
    color: #3b82f6;
    margin-right: 15px;
  }
  
  .redeem_arrow-buttons {
    display: flex;
    flex-direction: column;
  }
  
  .redeem_arrow {
    background: none;
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .redeem_arrow:hover {
    background-color:#3b82f6;
    color: white;
  }
  
  /* Cards */
 /*  .redeem_card-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    gap: 15px; 
    margin-bottom: 20px;
  }
  .redeem_card:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  } */
  
 /*  .redeem_card {
    padding: 20px;
    background-color:#3b82f6;
    color: white;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  } */

  .redeem_card-container {
    display: flex;
    flex-wrap: wrap; /* Allows the cards to wrap to the next row */
    justify-content: center; /* Centers the cards horizontally */
    gap: 15px; /* Space between the cards */
    margin-bottom: 20px;
    max-width: 600px; /* Adjust as needed based on the card size */
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  .lands-inr{
    display: flex;
    justify-content: space-between;
    background-color: black;
    width:100%;
    padding: 2px;
    border-radius: 8px;
  }

  .lands-text{
   color: white;
   
  }
  @media (max-width:480px) {

    .lands-text, .inr-text{
      font-size: 12px;
    }

    .redeem-card{
      width: auto;
      height: fit-content;
      


    }

    .redeem_card-container{

      display: grid;
      grid-template-columns: repeat(2,1fr);
    }
  }
  .inr-text{
    display: flex;
    align-items: center;
    color:white;
  }
  .item-redeem-name{
    font-weight: 700;
  }
  .redeem-go-back:hover{
     color: #e53935;
     cursor: pointer;
  }
  
  .redeem_card {
    width: 200px;
    flex: 1 1 calc(33.33% - 15px); /* Each card takes up 1/3 of the row minus the gap */
    /* max-width: calc(33.33% - 15px); Prevents the card from exceeding its allocated space */
    box-sizing: border-box; /* Ensures padding doesn't affect card width */
    height: 120px; /* Adjust card height */
    display: flex;
    align-items: center;
    justify-content: flex-end;
   
    flex-direction: column;
    background-color: rgb(15, 14, 14); 
    color: white;
    border-radius: 8px ;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    gap:20px;
    grid-template-columns: repeat(auto-fit,1fr);


    box-sizing: border-box;
    overflow: hidden;
    width: auto;
   

  }
  .redeem_card.selected {
    box-sizing: border-box;
    overflow: hidden;
    width: auto;
    height: 120px;
   
    border: 0.8px solid black;
    
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: transparent;
    color: black; /* Optional: change text color for contrast */
  }
  
  /* Add hover effect for animation */
  .redeem_card:hover {
    transform: scale(1.05); /* Slight zoom-in effect */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a shadow on hover */
  }
  
  
  
  
  /* Form */
  .redeem_form {
    margin-top: 20px;
  }
  
  .redeem_form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .redeem_input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .redeem_input.address {
    width: 100%;
  }
  
  .redeem_form-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .redeem_button {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .redeem_button.proceed {
    background-color:#3b82f6;
    color: white;
  }
  
  .redeem_button.cancel {
    background-color: #e53935;
    color: white;
  }
  
  .redeem_button:hover {
    opacity: 0.9;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .redeem_points {
      flex-direction: column;
    }
  
    /* .redeem_card-container {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    } */
  
    .redeem_form-row {
      flex-direction: column;
    }
  }
  

  .redeem-points {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .label {
    font-size: 16px;
    font-weight: bold;
  }
  
  .controls {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .btn {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn.decrement {
    background-color: #FF4B4B;
  }
  
  .btn.increment {
    background-color: #4CAF50;
  }
  
  .btn:hover {
    opacity: 0.8;
  }
  
  .value {
    font-size: 16px;
    font-weight: bold;
    min-width: 30px;
    text-align: center;
  }
  
  .value-input {
    width: 50px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
  }
  





  .redeem-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 150px;
    gap: 10px;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    padding: 10px;
    width: 100%;
  }
  
  .redeem-card {
    background: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    scroll-snap-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .redeem-card.selected {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
    transform: scale(1.05);
  }
  
  .redeem-card h2 {
    font-size: 18px;
    margin: 0;
  }
  
  .redeem-card h3 {
    font-size: 16px;
    margin: 5px 0 0;
  }
  
  .redeem-grid::-webkit-scrollbar {
    height: 8px;
  }
  
  .redeem-grid::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  
  .redeem-grid::-webkit-scrollbar-thumb:hover {
    background: #999;
  }