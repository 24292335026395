.reward-details-card {
    
    
    /* background-color: white; */
    /* border: 1px solid #4caf50; */
    /* border-radius: 2.5rem; */
    padding: 1.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    /* width: 900px; */
    margin: 0 auto;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  }
  
  .reward-details-card__title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .reward-details-card__header,
  .reward-details-card__project-name {
    font-weight: 500;
  }
  
  /* .reward-details-card__grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    font-size: 0.875rem;
  } */

  .reward-details-card__grid-container {
   /*  display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    font-size: 0.875rem; */
    display: grid;
      grid-template-columns: repeat(5, auto); /* Adjust column width to fit content */
      gap: 1rem;
      overflow-x: auto; /* Enable horizontal scrolling */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
      white-space: nowrap; /* Prevent wrapping */
      max-height: 400px;
      overflow-y:auto ;
    
  }

  .reward-details-card__grid-container_allproject{
    display: flex;
    flex-direction: column;
   
      overflow-x: auto; /* Enable horizontal scrolling */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
      white-space: nowrap; /* Prevent wrapping */
      /* Ensures rows stack vertically */
  
  }

  .reward-details-card__row_allproject {
    display: flex;
    flex-direction: row; /* Adjust alignment as needed */
    gap:10rem
  }

  
  
 


  



  
  
  
  .reward-details-card__font-medium {
    font-weight: 500;
  }
  
  .reward-details-card__total {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1rem;
    gap:10px;
  }
  
  .reward-details-card__project-grid {
    display: grid;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }
  
  .reward-details-card__project-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.875rem;
  }
  
  .reward-details-card__project-item-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:150px; 
    margin-bottom: 0.5rem; 
    font-size: 1.5rem;
    font-weight: 500;
    
  }
  
 /*  .reward-details-card__land-remaining {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:380px; 
    margin-bottom: 0.5rem; 
  } */

  .reward-details-card__land-remaining {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 380px; /* Default gap for larger screens */
    margin-bottom: 0.5rem;
    overflow-x: auto; /* Enable horizontal scroll when necessary */
    white-space: nowrap;  /* Prevent content from wrapping onto the next line */
     /* Ensure the container takes up full width */
    
  }
  
  @media (max-width: 391px) {
    .reward-details-card__land-remaining {
    /* width: 80%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
      gap: 10px; /* No gap for small screens */
      overflow-x: auto; /* Enable horizontal scroll for very small screens */
    }
    .reward-details-card__project-item-name {

      display: flex;
      flex-direction: column;
      gap:5px;
    }
  }
  
  @media (min-width: 391px) and (max-width: 439px) {
    .reward-details-card__land-remaining {
      gap: 0px; /* Gap between elements */
      overflow-x: auto; /* Enable horizontal scroll */
    }
  }
  
  @media (min-width: 440px) and (max-width: 539px) {
    .reward-details-card__land-remaining {
      gap: 50px; /* Medium gap for medium-sized screens */
    }
  }
  
  @media (min-width: 540px) and (max-width: 759px) {
    .reward-details-card__land-remaining {
      gap: 150px; /* Larger gap for larger screens */
    }
  }
  
  @media (min-width: 760px) {
    .reward-details-card__land-remaining {
      gap: 380px; /* Default gap for screens 760px and above */
    }

  }
  
  
  
  
  .reward-details-card__button {
    color: #4caf50;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.125rem;
    padding: 0;
    margin-left: 8px; /* Added to space the button properly from the land remaining text */
  }
  
  .reward-details-card__button:hover {
    text-decoration: underline;
  }
  
  @media (min-width: 640px) {
    .reward-details-card__title {
      font-size: 1.5rem;
    }
    .reward-details-card__grid-container {
      font-size: 1rem;
    }
    
  }
  
  @media (min-width: 768px) {
    .reward-details-card__title {
      font-size: 2rem;
    }
    .reward-details-card__grid-container {
      font-size: 1.125rem;
    }
    .reward-details-card__project-item-name {
      gap:5px;
      display: flex;
      flex-direction: column;
    }
  }
  
   /* For screens smaller than 350px */
   @media  screen and (max-width: 480px) {
    .reward-details-card__grid-container {
      display: grid;
      grid-template-columns: repeat(5, auto); /* Adjust column width to fit content */
      gap: 1rem;
      overflow-x: auto; /* Enable horizontal scrolling */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */
      white-space: nowrap; /* Prevent wrapping */
    }


   

    .reward-details-card__project-item-name {
      display: flex;
      flex-direction: column;
      gap:5px;
    }
  }