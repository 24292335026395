.privacy-link  {
    color : #3b82f6;
    &:hover {
       color :  #1b72ff; /* Change to blue on hover */
      text-decoration: underline; /* Add underline on hover */}
  }
  
.privacy-link-2{
    color : #3b82f6;
    &:hover {
       color :  #1b72ff; /* Change to blue on hover */
      text-decoration: underline; /* Add underline on hover */}
  }